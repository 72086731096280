import { template as template_b0ca86962e574216a40dde41c3941bc4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_b0ca86962e574216a40dde41c3941bc4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
