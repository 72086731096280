import { template as template_f1fce27d2e2a4b948f597fabe84b9075 } from "@ember/template-compiler";
const FKLabel = template_f1fce27d2e2a4b948f597fabe84b9075(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
