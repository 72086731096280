import { template as template_014db1dd892b40f2b14f72098e87906b } from "@ember/template-compiler";
const FKText = template_014db1dd892b40f2b14f72098e87906b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
